//
// Visibility
//
@use 'sass:list';

@mixin make-visibilities($visibilities: $visibilities, $breakpoints: $grid-breakpoints) {
  @each $key, $value in $visibilities {
    .#{$key} { visibility: $value; }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if(map-get($breakpoints, $breakpoint) > 0) {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        $media: map-get($media-prefix, up);
        @each $key, $value in $visibilities {
          .#{$key}-#{$media}#{$infix} { visibility: $value; }
        }
      }

      @if(map-get($breakpoints, $breakpoint) != list.nth(list.nth($grid-breakpoints, -1), 2)) {
        @include media-breakpoint-down($breakpoint, $breakpoints) {
          $media: map-get($media-prefix, down);
          @each $key, $value in $visibilities {
            .#{$key}-#{$media}#{$infix} { visibility: $value; }
          }
        }
      }
    }

    @include media-breakpoint-only($breakpoint, $breakpoints) {
      $media: map-get($media-prefix, only);
      @each $key, $value in $visibilities {
        .#{$key}-#{$media}#{$infix} { visibility: $value; }
      }
    }
  }
}
