input[type="checkbox"] {
    cursor: pointer;
    appearance: none;
    position: relative;
    width: 24px;
    height: 20px;
    background-image: url("../images/polygon-checkbox.png");
    background-color: transparent;

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    &:checked {
        width: 24px;
        height: 20px;
        color: $theme-color-1;
    }

    &:checked::after {
        content: "\2714";
        display: block;
        color: $theme-color-1;
        font-size: 14px;
        z-index: 1;

        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

input[type="radio"] {
    cursor: pointer;
    appearance: none;
    position: relative;
    width: 24px;
    height: 20px;
    background-image: url("../images/polygon-checkbox.png");
    background-color: transparent;
    
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    &:checked {
        width: 24px;
        height: 20px;
        color: $theme-color-1;
    }

    &:checked::after {
        content: "\2714";
        display: block;
        color: $theme-color-1;
        font-size: 14px;
        z-index: 1;

        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}