.category-navigation {
    flex-wrap: wrap;
    row-gap: 10px;
    
    &-link {
        display: flex;
        align-items: center;
        margin-right: 5px;
        &:after {
            content: "";
            display: block;
            width: 2px;
            height: 10px;
            background-color: $theme-color-9;
            margin-left: 5px;
        }

        &:last-of-type {
            margin-right: 0;
            &:after {
                content: none;
            }
        }

        &:hover {
            color: $theme-color-5;
        }

        &.active {
            color: $theme-color-5;
        }
    }
}