.products {

    &-heading {
        @include mobile {
            flex-direction: column;
            gap: 10px;
        }
    }

    &-heading-left {
        p {
            &:not(:last-of-type) {
                margin-right: 4px;
            }
        }
    }

    &-heading-right {
        gap: 20px;
        flex: 1;

        @include mobile {
            flex-direction: column;
            width: 100%;
        }
    }

    &-sort-item {
        max-width: 250px;
        width: 100%;

        @include mobile {
            max-width: 100%;
        }

        select {
            width: 100%;
            border: 1px solid $theme-color-9;
            border-radius: 8px;
            padding: 11px 15px;
            font-size: 12px;
            font-weight: 500;
        }
    }

    .contact-form-select-arrow {
        top: 17px;
    }

    &-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(218px, 218px));
        gap: 20px;

        @include mobile {
            justify-content: center;
        }
    }
}