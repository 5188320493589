.map {
    &-heading {
        height: 50px;
        img {
            width: 20px;
            height: 26px;
            margin-right: 15px;
        }
    }

    .accordions {
        height: 100%;
        padding: 10px;
        background-color: $theme-color-2;

        @include mobile {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            height: auto;
        }

        &-item {
            padding-left: 10px;
            padding-right: 10px;
        }
    
        &-items-wrapper {
            padding: 0;
            background-color: $white;
            border: 0;
        }

        .wp-editor {
            p {
                margin-bottom: 10px;
                font-weight: 500;
                font-size: 12px;
                line-height: 1;
            }

            a {
                width: 100%;
                height: 38px;
                background-color: $theme-color-5;
                border-radius: 10px;
                font-size: 14px;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                transition: all 0.3s ease;

                &:hover {
                    background-color: $theme-color-1;
                    transform: scale(1.05);
                }
            }
        }
    }

    &-col-right {
        position: relative;
        top: 50px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        @include mobile {
            border-radius: 10px;
            top: 0;
        }

        img {
            height: 100%;
            object-fit: cover;
        }
    }
}