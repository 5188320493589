//
// Links
//

a {
  color: $link-color;
  text-decoration: $link-decoration;

    &:hover {
      text-decoration: $link-hover-decoration;
    }
}
