.profile {
    &-form {
        max-width: 1170px;
        margin: 0 auto;
    }

    &-checkbox-label {
        cursor: pointer;
        input {
            width: auto;
            height: auto;
            appearance: auto;

            &:checked {
                width: auto;
                height: auto;
            }

            &::after {
                content: none;
            }

            &:checked::after {
                content: none;
            }
        }
    }

    &-form-left-top {
        .modal-form-label:last-of-type {
            margin-bottom: 0;
        }
    }

    &-form-left-bottom {
        .modal-form-label:last-of-type {
            margin-bottom: 40px;
        }
    }

    &-form-label-half {
        gap: 30px;

        @include mobile-m {
            flex-direction: column;
            gap: 0;
        }

        .modal-form-label:last-of-type {
            margin-bottom: 15px;
        }
    }

    &-form-btn {
        width: 100%;
    }

    &-deactivate {
        width: fit-content;
        margin: 0 auto;
    }
}