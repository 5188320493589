.search { 
    &-heading {
        word-wrap: break-word;
        span {
            font-size: 24px;
            font-weight: 600;
            color: $theme-color-5;
        }
    }
    &-inner {
        p {
            line-height: 1.3;
        }
    }

    & + .sign-up {
        @extend .sign-up-bg;
    }
}

.promo + .search {
    margin-top: 50px;
}