.footer {
    &-left-col, &-mid-col, &-right-col {
        a:hover {
            color: $theme-color-5;
        }
    }

    &-bottom {
        a {
            img {
               width: 85px;
               height: 22px; 
            }
        }
    }
}