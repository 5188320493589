// Reboot

// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Change the default tap highlight to be completely transparent in iOS.
*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

%reset-margin {
  margin: 0;
}

%reset-padding {
  padding: 0;
}

%reset-outline {
  outline: none;
}

html {
  font-family: sans-serif; // 2
  line-height: 1.15; // 3
  -webkit-text-size-adjust: 100%; // 4
  -webkit-tap-highlight-color: rgba($black, 0); // 5
}

article,
aside,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}

body {
  @extend %reset-margin; // 1
}

noscript {
  display: none;
}
