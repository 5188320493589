//
// Borders
//
@use 'sass:list';

//
// Border
//
@mixin make-border($sizes: $border-width, $positions: $border-position, $styles: $border-styles, $colors: $border-colors, $breakpoints: $grid-breakpoints) {
  @each $key, $val in $sizes {
    @each $c_key, $c_val in $colors {
      @each $s_key, $s_val in $styles {
        .#{$border-prefix}-#{$key}-#{$s_key}-#{$c_key} { border: $val $s_val $c_val; }
        @each $p_key, $p_val in $positions {
          .#{$border-prefix}-#{$p_key}-#{$key}-#{$s_key}-#{$c_key} { border-#{$p_val}: $val $s_val $c_val; }
        }
      }
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if(map-get($breakpoints, $breakpoint) > 0) {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        $media: map-get($media-prefix, up);
        @each $key, $val in $sizes {
          @each $c_key, $c_val in $colors {
            @each $s_key, $s_val in $styles {
              .#{$border-prefix}-#{$media}#{$infix}-#{$key}-#{$s_key}-#{$c_key} { border: $val $s_val $c_val; }
              @each $p_key, $p_val in $positions {
                .#{$border-prefix}-#{$media}#{$infix}-#{$p_key}-#{$key}-#{$s_key}-#{$c_key} { border-#{$p_val}: $val $s_val $c_val; }
              }
            }
          }
        }
      }

      @if(map-get($breakpoints, $breakpoint) != list.nth(list.nth($grid-breakpoints, -1), 2)) {
        @include media-breakpoint-down($breakpoint, $breakpoints) {
          $media: map-get($media-prefix, down);
          @each $key, $val in $sizes {
            @each $c_key, $c_val in $colors {
              @each $s_key, $s_val in $styles {
                .#{$border-prefix}-#{$media}#{$infix}-#{$key}-#{$s_key}-#{$c_key} { border: $val $s_val $c_val; }
                @each $p_key, $p_val in $positions {
                  .#{$border-prefix}-#{$media}#{$infix}-#{$p_key}-#{$key}-#{$s_key}-#{$c_key} { border-#{$p_val}: $val $s_val $c_val; }
                }
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-only($breakpoint, $breakpoints) {
      $media: map-get($media-prefix, only);
      @each $key, $val in $sizes {
        @each $c_key, $c_val in $colors {
          @each $s_key, $s_val in $styles {
            .#{$border-prefix}-#{$media}#{$infix}-#{$key}-#{$s_key}-#{$c_key} { border: $val $s_val $c_val; }
            @each $p_key, $p_val in $positions {
              .#{$border-prefix}-#{$media}#{$infix}-#{$p_key}-#{$key}-#{$s_key}-#{$c_key} { border-#{$p_val}: $val $s_val $c_val; }
            }
          }
        }
      }
    }
  }
}

//
// Border Radius
//
@mixin make-border-radius($radius: $border-radius, $breakpoints: $grid-breakpoints) {
  @each $key, $value in $radius {
    .#{$border-radius-prefix}-#{$key} { border-radius: $value; }
    .#{$border-radius-prefix}-t-#{$key} { @include border-top-radius($value); }
    .#{$border-radius-prefix}-b-#{$key} { @include border-bottom-radius($value); }
    .#{$border-radius-prefix}-l-#{$key} { @include border-left-radius($value); }
    .#{$border-radius-prefix}-r-#{$key} { @include border-right-radius($value); }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if(map-get($breakpoints, $breakpoint) > 0) {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        @each $key, $value in $radius {
          $media: map-get($media-prefix, up);
          .#{$border-radius-prefix}-#{$media}#{$infix}-#{$key} { border-radius: $value; }
          .#{$border-radius-prefix}-t-#{$media}#{$infix}-#{$key} { @include border-top-radius($value); }
          .#{$border-radius-prefix}-b-#{$media}#{$infix}-#{$key} { @include border-bottom-radius($value); }
          .#{$border-radius-prefix}-l-#{$media}#{$infix}-#{$key} { @include border-left-radius($value); }
          .#{$border-radius-prefix}-r-#{$media}#{$infix}-#{$key} { @include border-right-radius($value); }
        }
      }

      @if(map-get($breakpoints, $breakpoint) != list.nth(list.nth($grid-breakpoints, -1), 2)) {
        @include media-breakpoint-down($breakpoint, $breakpoints) {
          @each $key, $value in $radius {
            $media: map-get($media-prefix, down);
            .#{$border-radius-prefix}-#{$media}#{$infix}-#{$key} { border-radius: $value; }
            .#{$border-radius-prefix}-t-#{$media}#{$infix}-#{$key} { @include border-top-radius($value); }
            .#{$border-radius-prefix}-b-#{$media}#{$infix}-#{$key} { @include border-bottom-radius($value); }
            .#{$border-radius-prefix}-l-#{$media}#{$infix}-#{$key} { @include border-left-radius($value); }
            .#{$border-radius-prefix}-r-#{$media}#{$infix}-#{$key} { @include border-right-radius($value); }
          }
        }
      }
    }

    @include media-breakpoint-only($breakpoint, $breakpoints) {
      @each $key, $value in $radius {
        $media: map-get($media-prefix, only);
        .#{$border-radius-prefix}-#{$media}#{$infix}-#{$key} { border-radius: $value; }
        .#{$border-radius-prefix}-t-#{$media}#{$infix}-#{$key} { @include border-top-radius($value); }
        .#{$border-radius-prefix}-b-#{$media}#{$infix}-#{$key} { @include border-bottom-radius($value); }
        .#{$border-radius-prefix}-l-#{$media}#{$infix}-#{$key} { @include border-left-radius($value); }
        .#{$border-radius-prefix}-r-#{$media}#{$infix}-#{$key} { @include border-right-radius($value); }
      }
    }
  }
}

@mixin border-top-radius($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-right-radius($radius) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

//
// Border Images
//
@mixin make-border-images($sizes: $border-width, $positions: $border-position, $styles: $border-styles, $colors: $border-colors, $breakpoints: $grid-breakpoints) {
  @each $key, $val in $sizes {
    @each $c_key, $c_val in $colors {
      .#{$border-imgage-prefix}-#{$key}-#{$c_key} {
        border: $val solid transparent;
        border-image: linear-gradient(to right, $c_val 0%, transparent 35%, transparent 65%, $c_val 100%) 1 1;
      }
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if(map-get($breakpoints, $breakpoint) > 0) {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        $media: map-get($media-prefix, up);

        @each $key, $val in $sizes {
          @each $c_key, $c_val in $colors {
            .#{$border-imgage-prefix}-#{$media}#{$infix}-#{$key}-#{$c_key} {
              border: $val solid transparent;
              border-image: linear-gradient(to right, $c_val 0%, transparent 35%, transparent 65%, $c_val 100%) 1 1;
            }
          }
        }
      }

      @if(map-get($breakpoints, $breakpoint) != list.nth(list.nth($grid-breakpoints, -1), 2)) {
        @include media-breakpoint-down($breakpoint, $breakpoints) {
          $media: map-get($media-prefix, down);
          @each $key, $val in $sizes {
            @each $c_key, $c_val in $colors {
              .#{$border-imgage-prefix}-#{$media}#{$infix}-#{$key}-#{$c_key} {
                border: $val solid transparent;
                border-image: linear-gradient(to right, $c_val 0%, transparent 35%, transparent 65%, $c_val 100%) 1 1;
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-only($breakpoint, $breakpoints) {
      $media: map-get($media-prefix, only);

      @each $key, $val in $sizes {
        @each $c_key, $c_val in $colors {
          .#{$border-imgage-prefix}-#{$media}#{$infix}-#{$key}-#{$c_key} {
            border: $val solid transparent;
            border-image: linear-gradient(to right, $c_val 0%, transparent 35%, transparent 65%, $c_val 100%) 1 1;
          }
        }
      }
    }
  }
}
