//
// Grid
//
.container {
  @include make-container;
  @include make-container-max-widths();

    .container {
      padding-left: 0;
      padding-right: 0;
    }

    &--sm {
      @include media-breakpoint-up(lg) {
        max-width: map-get($container-max-widths, 'md');
      }
    }
}

.container-fluid {
  @include make-container;
  max-width: $container-width-fluid;
}

@include make-grid-system();

.ng {
  margin-right: 0;
  margin-left: 0;

    > [class*=#{$col-prefix}] {
      padding-right: 0;
      padding-left: 0;
    }
}
