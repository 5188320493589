//
// Stretched link
//

.l-str {

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 5;
      pointer-events: auto;
      background-color: rgba(0, 0, 0, 0);
    }
}
