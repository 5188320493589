.filter {

    &-outer {
        position: fixed;
        top: 0;
        left: 0;
        transform: translateX(calc(-100% + 33px));
        transition: transform 0.3s ease;
        height: 100%;
        z-index: 101;

        &.show {
            transform: translateX(0);
    
            .filter-btn .filter-btn-text {
                opacity: 0;
                pointer-events: none;
            }
        }  
    }

    &-btn {
        gap: 8px;
        width: fit-content;
        transition: transform 0.3s ease;
        align-self: center;
    }



    &-image-wrapper {
        width: 33px;
        height: 32px;
        
        img {
            width: 19px;
            height: 16px;
        }
    }

    &-btn-text {
        writing-mode: tb-rl;
        opacity: 1;
    }

    &-wrapper-outer {
        height: 650px;
        align-self: center;
    }

    &-wrapper {
        height: 650px;
        overflow: auto;
        max-width: 210px;
        width: 100%;
        align-self: center;
    }

    &-clear-all-btn {
        height: 30px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        align-items: unset !important;
        line-height: 30px;
    }

    &-cat-label {
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;


        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &-clear {
        width: fit-content;
        margin: 0 auto;
        img {
            width: 12px;
            height: 12px;
        }
    }

    &-cat-wrapper {
        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }
    // INPUT RANGES SLIDER
    &-sliders-wrapper {
        height: 9px;
    }

    // INPUTS
    &-slider-inputs {
        input {
            width: 50px;
            height: 30px;
            border-radius: 8px;
            padding-right: 25px;
            text-align: right;
            font-size: 12px;
            font-weight: 500;
            appearance: none;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                appearance: none;
            }
        }
    }

    &-slider {
        input[type="range"] {
            position: absolute;
            left: 0;
            width: 100%;
            appearance: none;
            background: transparent;
            cursor: pointer;
        }

        // INPUT RANGE
        input[type="range"]::-ms-thumb {
            z-index: 2;
            position: relative;
            width: 25px;
            height: 10px;
            border-radius: 4px;
            background-color: $theme-color-3;
            margin-top: -5px;
            appearance: none;
        }

        input[type="range"]::-webkit-slider-thumb {
            z-index: 2;
            position: relative;
            width: 25px;
            height: 10px;
            border-radius: 4px;
            background-color: $theme-color-3;
            margin-top: -5px;
            appearance: none;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            background: $theme-color-6;
            box-shadow: none;
            border: 0;
            height: 1px;
        }

        input[type="range"]::-ms-track {
            background-color: $theme-color-6;
            box-shadow: none;
            border: 0;
            height: 1px;
            width: 100%;
            background: transparent;
            border-color: transparent;
            color: trnsparent;
        }

        input[type="range"]::-ms-fill-lower,
        input[type="range"]::-ms-fill-lower {
            background: $theme-color-6;
            box-shadow: none;
            border: 0;
        }
    }

    &-cat-inner-wrapper {
        max-height: 180px;
        overflow: auto;
    }
}

.input-range-currency {
    font-size: 12px;
    font-weight: 500;
    color: $black;
    position: absolute;
    pointer-events: none;

    &:first-of-type {
        left: 30px;
    }

    &:last-of-type {
        right: 5px;
    }
}