.single-news {
    &-image-wrapper, .wp-editor {
        flex: 1;
    }

    &-image-wrapper {
        height: 450px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-wrapper {
        gap: 30px;

        @include mobile {
            flex-direction: column;
        }
    }

    .wp-editor {
        h1 {
            font-size: 30px;
            margin-bottom: 15px;
            font-weight: 600;
        }

        p {
            font-size: 15px;
            line-height: 1.3;

            &:not(:last-of-type) {
                margin-bottom: 30px;
            }
        }
    }
}

.news + .sign-up {
    @extend .sign-up-bg;
}