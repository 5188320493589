// Flex Object Center
.foc {
  display: inline-flex;
  align-items: center;
  justify-content: center;

    img {
      width: auto;
      max-height: 100%;
    }
}
