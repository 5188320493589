.modal {
    &-compare {
        width: 350px;
    }

    &-product {
        .remove-item {
            align-self: center;
            img {
                width: 8px;
                height: 8px;
            }
        }
    }
}