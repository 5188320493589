.about {
    &-image-wrapper {
        height: 230px;
        flex: 1;

        @include mobile {
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .wp-editor {
        flex: 1;
        h1 {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 35px;
            line-height: 1.3;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &-item-wrapper {
        gap: 30px;
        
        @include mobile {
            flex-direction: column;
        }

        &:not(:last-of-type) {
            margin-bottom: 40px;
        }
        
        &:nth-of-type(even) {
            flex-direction: row-reverse;

            @include mobile {
                flex-direction: column;
            }
            
            .wp-editor {
                text-align: right;

                @include mobile {
                    text-align: left;
                }
            }
        }
    }
}