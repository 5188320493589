//
// Spacing
//
$spacers: (
  0: 0,
  3: 3px,
  5: 5px,
  8: 8px,
  10: 10px,
  12: 12px,
  15: 15px,
  20: 20px,
  25: 25px,
  30: 30px,
  35: 35px,
  40: 40px,
  45: 45px,
  46: 46px,
  50: 50px,
  60: 60px,
  70: 70px,
  75: 75px,
  80: 80px,
  90: 90px,
  100: 100px,
  130: 130px,
  140: 140px,
  160: 160px,
);
