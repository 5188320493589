.modal[data-attribute="modal-review"] {
    .header-cart-image-wrapper { 
        height: 90px;
        min-width: 90px;
    }

    .header-cart-item {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .single-product-review-wrapper { 
        width: fit-content;
        margin: 20px auto;
    }

    .single-product-score {
        &::after {
            content: none;
        }
    }

    .contact-form-label {
        textarea {
            height: 150px;
            border: 1px solid $theme-color-17;
            border-radius: 10px;

            &:focus {
                border: 2px solid $theme-color-5;
            }
        }
    }

    .single-product-review-stars {
        gap: 0;

        input {
            background: none;
            display: none;
        }

        label {
            float: right;
        }

        label:before {
            display: inline-block;
            font-size: 1.1rem;
            padding-right: 3px;
            cursor: pointer;
            margin: 0;
            font-family: "FontAwesome";
            content: "\f005";
        }

        input:checked ~ label, label:hover, label:hover ~ label {
            color: $theme-color-5;
        }

        input:checked + label:hover, input:checked ~ label:hover, 
        input:checked ~ label:hover ~ label, 
        label:hover ~ input:checked ~ label {
            color: $theme-color-5;
        }
    }
}

