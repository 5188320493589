// 
// Position
//
$position-prexix: 'ps';
$positions: (
  's': static,
  'r': relative,
  'a': absolute,
  'f': fixed
);
