//
// Buttons
//
@mixin make-btn-colors($colors: $btn-colors) {
  @each $key, $value in $colors {
    .#{$btn-prefix}-f--#{$key} {
      background-color: $value;
    }
  }
}

@mixin make-btn-ghost($colors: $btn-colors) {
  @each $key, $value in $colors {
    .#{$btn-prefix}-g--#{$key} {
      border-image: linear-gradient(to right, $value 0%, transparent 35%, transparent 65%, $value 100%) 1 1;

        &:hover {
          @if $key == 'w' {
            color: $black;
          } @else {
            color: $white;
          }
          background-color: $value;
        }
    }
  }
}
