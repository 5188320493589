.cart {
    &-guest-heading-left {
        img {
            width: 50px;
            height: 53px;
            margin-right: 20px;
        }
    }

    &-guest-btn {
        flex: 1;

        @include mobile-m {
            flex: auto;
            width: 100%;
        }
    }

    &-guest-btn-wrapper {
        gap: 30px;

        @include mobile-m {
            gap: 15px;
        }
    }
}