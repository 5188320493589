.tables {
    &-address-table-wrapper {
        @extend .tables-table-wrapper;

        table {
            td {
                display: flex;
                flex-direction: column;

                &:first-of-type {
                    padding-top: 10px !important;
                }
            }

            th {
                position: relative;
                font-size: 12px;
                font-weight: 400;
                color: #003333;
                padding-bottom: 15px;
                border-bottom: 1px solid #dedede;

                img {
                    position: absolute;
                    width: 5px;
                    height: 5px;
                }

                span {
                    img:first-of-type {
                        top: 1px;
                    }
                    img:last-of-type {
                        top: 8px;
                    }
                }
            }

            tr:last-of-type { 
                td {
                    padding-bottom: 10px;
                    border-bottom: 1px solid $theme-color-11;
                }
            }
        }

        input[name="address"] {
            width: 100%;
            overflow: auto;
        }

        input[type="radio"] {
            background: url("../images/polygon-border.png");
            width: 32px;
            height: 28px;
            min-width: 32px;

            &:checked {
                width: 32px;
                height: 28px;
            }
        }
    }

    &-address-number {
        background: url("../images/polygon-dark.png");
        background-repeat: no-repeat;
        min-width: 32px;
        height: 28px;
    }

    &-address-remove, &-address-edit {
        background: url('../images/polygon-color.png');
        min-width: 32px;
        height: 28px;
    }

    &-address-remove {
        img {
            width: 14px;
            height: 15px;
        }
    }

    &-address-edit {
        img {
            width: 14px;
            height: 14px;
        }
    }

    &-address-checkbox-label {
        cursor: pointer;
        width: fit-content;
        gap: 10px;
        white-space: nowrap;
    }

    &-add-address-wrapper {
        padding-top: 30px;
        width: 100%;

        input {
            max-width: 450px;
            width: 100%;
            height: 40px;
            border: 1px solid $theme-color-11;
            border-radius: 10px;
            padding-left: 15px;

            @include mobile {
                margin-bottom: 15px;

                @include mobile {
                    max-width: 350px;
                }
            }

            &:first-of-type {
                margin-right: 20px;
            }

            &:focus {
                border: 2px solid $theme-color-5;
            }
        }

        .contact-form-label {
            max-width: 350px;
        }

        input {
            @include mobile {
                margin-bottom: 0;
            }
        }
    }

    &-address-add, &-address-cancel {
        @extend .tables-address-remove, .tables-address-edit;
    }

    &-address-add {
        img {
            width: 12px;
            height: 9px;
        }
    }

    &-address-cancel {
        img {
            width: 14px;
            height: 15px;
        }
    }

    &-btn-address-add {
        @extend .contact-form-btn;
        margin: 0 auto;
        margin-top: 30px;
    }

    &-address-type {
        min-width: 92px;
        height: 28px;
    }

    &-address-line {
        width: 1px;
        height: 28px;
    }

    .contact-form-label {
        width: 100%;

        select { 
            border: 1px solid $theme-color-11;
        }
    }

    &-address-wrapper {
        width: 100%;
    }

    &-address-wrapper-edit {
        display: none;
        .contact-form-label {
            width: 30%;

            @include mobile {
                width: 50%;
            }
        }

        input {
            width: 100%;
            height: 40px;
            border: 1px solid #dedede;
            border-radius: 10px;
            padding-left: 15px;

            @include mobile {
                max-width: 350px;
            }
        }

        .tables-address-check { 
            @include mobile {
                margin-left: 0;
            }
        }
    }

    &-address-check, &-address-close {
        margin-right: 0;
        @extend .tables-address-add;
    }

    &-address-check {
        margin-right: 5px;
        margin-left: 25px;
    }

    &-address-close {
        img {
            width: 12px;
            height: 12px;
        }
    }
}