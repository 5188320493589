.tables {

    .pagination {
        margin-top: 30px;
    }

    &-table-wrapper {
        padding: 20px 25px 30px 25px;
        overflow-x: auto;
    }

    table {
        width: 100%;
        table-layout: fixed;

        th {
            cursor: pointer;
            position: relative;
            font-size: 12px;
            font-weight: 400;
            color: $theme-color-1;
            padding-bottom: 15px;
            border-bottom: 1px solid $theme-color-11;

            @include mobile {
                width: 700px;
            }

            img {
                position: absolute;
                width: 5px;
                height: 5px;
            }

            span {
                display: inline-block;

                img {
                    &:first-of-type {
                        top: 1px;
                    }

                    &:last-of-type {
                        top: 8px;
                    }
                }
            }
        }
        
        tr {
            td {
                font-size: 14px;
                font-weight: 500;
                color: $theme-color-1;
                padding-top: 15px;
            }
        }

        // tr:nth-of-type(2) {
        //     td {
        //         padding-top: 15px;
        //     }
        // }
    }

    &-active-orders, &-finalized-orders  { 
        table th {
          width: 150px;
        }
    }
}