@use 'sass:math';

.wp-editor {
  line-height: math.div(28, 16);

    a {
      font-family: $font-family-base;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    p,
    table {

        &:not(:last-child) {
          margin-bottom: 30px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $headings-color;

        @include media-breakpoint-up(sm) {
          clear: both;
        }
    }

    li {

        &:not(:last-child) {
          margin-bottom: 10px;
        }
    }

    ul {
      position: relative;
      font-weight: $font-weight-medium;

        li {
          position: relative;
          padding-left: 20px;

            &::before {
              content: '';
              position: absolute;
              top: 7px;
              left: 2px;
              width: 0;
              height: 0;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-left: 7px solid $theme-color-1;
            }
        }
    }

    table {
      width: 100%;
      border: none;
      overflow: auto;
      font-size: 14px;

        @include media-breakpoint-down(xs) {
          display: block;
        }
    }

    th,
    td {
      padding: 10px;
      border: none;
    }

    thead {

        th,
        td {
          color: $white;
          font-weight: $font-weight-bold;
          background-color: $theme-color-2;
          text-transform: uppercase;
        }
    }

    tbody {

        tr {

            &:nth-child(even) {

                td {
                  background: $theme-color-7;
                }
            }
        }
    }
}

img {

    &.alignnone,
    &.aligncenter,
    &.alignleft,
    &.alignright {
      margin-bottom: 20px;
    }

    &.alignnone {
      display: block;
    }

    &.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    &.alignleft {

        @include media-breakpoint-up(md) {
          float: left;
          margin-right: 20px;
        }
    }

    &.alignright {

        @include media-breakpoint-up(md) {
          float: right;
          margin-left: 20px;
        }
    }

    &.alignleft,
    &.alignright {

        @include media-breakpoint-down(sm) {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
    }
}
