.news {
    &-wrapper {
        @extend .info-wrapper;

        grid-template-columns: repeat(auto-fit, minmax(270px, 330px));
        justify-content: center;
    }

    &-item-date {
        z-index: 1;
        bottom: 0;
        left: 0;
        max-width: 80px;
        width: 100%;
        height: 27px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    &-image-link {
        height: 220px;
        transition: transform 0.3s ease;

        &:hover {
            img {
                transform: scale(1.05);
            }
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &-heading-image-wrapper {
        @extend .polygon-c5;
        img {
            width: 17px;
            height: 16px;
        }
    }

    &-item-link:hover {
        text-decoration: underline;
    }

    &-item {
        transition: transform 0.3s ease;
    }

    &-item:hover {
        transform: translateY(-10px);
    }
}