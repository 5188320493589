.info-logged {
    .info-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }

    .info-item-right {
        padding-right: 20px;
        width: 100%;
        justify-content: space-between;
    }
}