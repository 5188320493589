.info {
    &-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 330px));
        gap: 30px;

        @include mobile {
            justify-content: center;
        }
    }

    &-item {
        display: flex;
        align-items: center;
        height: 90px;

        .info-logged-btn {
            @include mobile {
                display: none;
            }
        }

        & + .info-logged-btn {
            @include desktop {
                display: none;
            }
        }
    }

    &-item-left {
        border-radius: 10px;
        border-top-right-radius: 0;
        min-width: 90px;
        max-width: 90px;
        width: 100%;
    }

    &-item-right {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -5px;
            width: 5px;
            height: 5px;
            border-bottom: 5px solid #003333;
            border-left: 5px solid transparent;
        }
    }

    &-item:first-of-type {
        img {
            width: 40px;
            height: 45px;
        }
    }

    &-item:nth-of-type(2) {
        img {
            width: 52px;
            height: 31px;
        }
    }

    &-item:nth-of-type(3) {
        img {
            width: 46px;
            height: 46px;
        }
    }

    &-item:nth-of-type(4) {
        img {
            width: 44px;
            height: 39px;
        }
    }
}