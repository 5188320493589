.products {

    &-item {
        position: relative;
        display: flex;
        flex-direction: column;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid $theme-color-1;
            border-radius: 10px;
            transition: opacity 0.3s ease;
            pointer-events: none;
            opacity: 0;
        }
    }

    &-bottom {
        transition: padding 0.3s ease;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &-item:hover {
        &:after {
            opacity: 1;
        }

        .products-btns-wrapper {
            opacity: 1;
        }

        .products-image-outer {
            border: 0;
        }

        .products-price {
            border-bottom-left-radius: 0;
        }

        .products-image-outer {
            &:after {
                opacity: 0;
            }
        }

        .products-image img { 
            transform: scale(1.1);
        }
    }

    &-bottom {
        padding:0 10px 10px 10px;
    }

    &-image-outer {
        height: 250px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid $theme-color-6;
            border-radius: 10px;
            z-index: -1;
            transition: opacity 0.3s ease;
            opacity: 1;
        }
    }

    &-image {
        height: 100%;

        img {
            padding: 0 20px;
        }
    }

    &-new-banner {
        border-top-right-radius: 10px;
        border-bottom-left-radius: 8px;
        top: 0;
        right: 0;
        padding: 10px;
        width: fit-content;
        text-align: center;
    }

    &-price {
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 8px;
        transition: all 0.3s ease;
    }

    &-name {
        &:after {
            content: '';
            display: block;
            background: rgb(223,223,223);
            background: linear-gradient(90deg, rgba(223,223,223,1) 0%, rgba(255,255,255,1) 100%);
            width: 100%;
            height: 1px;
            margin: 10px 0;
        }
    }

    &-cart-btn {
        max-width: 100px;
        width: 100%;
        height: 30px;
        transition: all 0.3s ease;

        &:hover {
            background-color: $theme-color-1;
            transform: scale(1.05);
        }

        img {
            width: 16px;
            height: 16px;
        }
    }

    &-btns-wrapper {
        opacity: 0;
        transition: all 0.3s ease;
        margin-top: auto;

        @include mobile {
            opacity: 1;
        }
    }

    &-promo-banner {
        height: 30px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 10px;
        bottom: 38px;
        z-index: 1;
    }
}

