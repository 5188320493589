.product-review {

    &-heading {
        @include mobile-m {
            flex-direction: column;
            align-items: center;
            gap: 15px;
        }
    }

    &-score {
        height: 40px;

        @include mobile-m {
            padding: 10px 15px;
            height: auto;
        }
    }

    &-add-btn {
        @extend .error-btn-begining;
        font-weight: 400;
    }

    &-comment {
        line-height: 1.3;
    }

    &-comment-wrapper {
        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }

    & + .products {
        margin-bottom: 100px;
    }
}