.cart {
    &-form {
        gap: 30px;
        max-width: 1060px;
        margin-left: auto;

        @include mobile {
            max-width: 100%;
        }
    }

    &-form-left-col-top {
        .modal-form-label:last-of-type {
            margin-bottom: 0;
        }
    }

    .profile-form-label-half {
        .modal-form-label {
            flex: 1;
        }
    }

    &-form-right-col {
        .contact-form-label {
            margin-bottom: 50px;

            @include mobile-m {
                margin-bottom: 15px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            select {
                height: 40px;
                border: 1px solid $theme-color-14;
            }
    
            textarea {
                height: 90px;
                border: 1px solid $theme-color-14;

                &:focus {
                    border: 2px solid $theme-color-5;
                }
            }
        }
    
        .contact-form-label:last-child {
            margin-top: 50px;
        }
    }
}