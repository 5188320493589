//
// Buttons
//
.#{$btn-prefix} {
  display: inline-block;
  position: relative;
  max-width: 100%;
  height: $btn-height;
  padding: $btn-padding-y $btn-padding-x;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $btn-color;
  text-align: center;
  line-height: $btn-line-height;
  text-decoration: $btn-decoration;
  white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border-radius: $btn-border-radius;
  transition: $btn-transition;

    @include media-breakpoint-up(sm) {
      font-size: $btn-font-size-desktop;
    }

    @include media-breakpoint-down(xs) {
      font-size: $btn-font-size-mobile;
    }

    &::before {
      content: '';
      position: absolute;
      background-color: transparent;
      mix-blend-mode: overlay;
      border-radius: inherit;
      transition: inherit;
    }

    &:hover {
      text-decoration: $btn-hover-decoration;
    }

    &-f {

        &::before {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        &::after {
          content: '';
          position: absolute;
          top: 5px;
          right: 5px;
          bottom: 5px;
          left: 5px;
          z-index: 2;
          border: 1px solid;
          border-image: $border-image-white;
        }

        &:hover {

            &:before {
              background-color: $black;
            }
        }
    }

    &-g {
      border: $btn-border-width solid transparent;
    }

    &--176 {
      width: 176px;
    }

    &--250 {
      width: 250px;
    }

    &.disabled,
    &:disabled {
      opacity: $btn-disabled-opacity;
    }
}

a.#{$btn-prefix} {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
}

a:hover {
  text-decoration: none;
}

.btn:hover {
  transform: scale(1.07);
}

.btn-disabled {
  background-color: $theme-color-17 !important;
  pointer-events: none !important;
}

//
// Alternate buttons
//
@include make-btn-colors();
@include make-btn-ghost();
