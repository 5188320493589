select, textarea {
    appearance: none;
    resize: none;
}

textarea {
    border-radius: 10px;
    background-color: $white;
}

input {
    background-color: $white;
}

select {
    cursor: pointer;
}

input::placeholder, select::placeholder, textarea::placeholder {
    font-size: 14px;
    font-weight: 400;
}