.accordions {
    &-items-wrapper {
        padding: 0 10px;
        &:not(:last-of-type) {
            margin-bottom: 15px;
        }

        &.active {

            img {
                transform: rotate(180deg);
            }
        }
    }

    &-item {
        cursor: pointer;
        padding: 15px 0;

        img {
            pointer-events: none;
            margin-left: auto;
            width: 14px;
            height: 8px;
        }
    }

    .wp-editor {
        border-top: 1px solid $theme-color-9;
        padding-top: 15px;
        padding: 15px 10px 15px 10px; 
    }

    &-number {
        @extend .polygon-c5;
    }

    & + .sign-up {
        @extend .sign-up-bg;
    }
}