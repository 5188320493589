.tables {
  &-cashback-image-wrapper {
    span {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -85%);
    }
  }

  &-cashback-table {
      &-item {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $theme-color-11;
    
        &:first-of-type {
          padding-top: 0;
          .tables-cashback-image-wrapper {
            img {
              min-width: 37px;
              height: 44px;
            }
          }
        }
    
        &:last-of-type {
          padding-bottom: 0;
          border: 0;
          .tables-cashback-image-wrapper {
            img {
              min-width: 41px;
              height: 40px;
            }
          }
        }
    }
  }
}

.tables-cashback + .tables-promocodes {
  table th {
    width: 130px;
  }
}
