.compare {
    &-empty {
        p:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }

    & + .promo {
        margin-top: 60px;
    }
}