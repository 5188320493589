.cookies {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;

    &-container {
        max-width: 1230px;
        padding: 0 30px;
        margin: 0 auto;
        margin-top: 200px;
    }

    &-inner {
        padding: 35px 30px;

        p {
            line-height: 1.2;
            a {
                color: $theme-color-1;
                text-decoration: underline;
            }
        }
    }

    &-right {
        gap: 20px;
    }
}

.btn-cookies {
    @extend .error-btn-begining;
}