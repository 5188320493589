.error {
    &-text {
        line-height: 1.5;

        &:first-of-type {
            margin-top: 15px;
        }

        &:last-of-type {
            margin-bottom: 35px;
        }
    }

    &-btn-begining {
        max-width: 210px;
        width: 100%;
    }
}

label.error {
    .error-message {
        display: block;
    }

    input {
        border: 2px solid $theme-color-18;
    }
}