.sign-up-bg {
    position: relative;
    &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -100px;
        right: 15px;
        width: 100%;
        height: 690px;
        @extend .contact-wrapper;
        background-image: url("../images/terms-backr.png") !important;
        z-index: -1;
    }
}

.line-seperator-before {
    &:before {
        content: "";
        display: block;
        width: 1px;
        height: 40px;
        background-color: #dddddd;
        margin-right: 20px;
    }
}

.polygon-c5 {
    background-image: url("../images/polygon-color.png");
    background-repeat: no-repeat;
    position: relative;
    min-width: 32px;
    height: 28px;
}

.range-thumb {
    z-index: 2;
    position: relative;
    width: 25px;
    height: 10px;
    border-radius: 4px;
    background-color: $theme-color-3;
    margin-top: -7px;
    appearance: none;
}