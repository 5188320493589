//
// Floats
//
$flaot-prefix: 'float';
$floats: (
  'l': left,
  'r': right,
  'n': none
);

