//
// Sizing
//
$sizes: (
  '25': 20%,
  '50': 50%,
  '75': 75%,
  '100': 100px,
  'a': auto,
  'full': 100%,
);
