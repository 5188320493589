//
// Buttons
//
$btn-prefix: 'btn';
$btn-height: 40px;
$btn-padding-y: 0;
$btn-padding-x: 25px;
$btn-font-size-desktop: 14px;
$btn-font-size-mobile: 14px;
$btn-line-height: null;
$btn-border-radius: null;

$btn-font-family: $font-family-base;
$btn-font-weight: $font-weight-bold;
$btn-color: $link-color;
$btn-hover-color: $link-hover-color;
$btn-decoration: if($link-decoration == none, null, none);
$btn-hover-decoration: none;
$btn-white-space: nowrap;
$btn-border-width: 1px; // 1px
$btn-border-style: solid; // Solid
$btn-border-color: transparent; // transparent

$btn-transition: 250ms ease-in-out;

$btn-disabled-opacity: .5;

$btn-colors: map-merge((
), $theme-colors);
