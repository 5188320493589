.sign-up {
    &-btn {
        @extend .error-btn-begining;

        background-color: $theme-color-5;
    }

    &-form, &-right-wrapper {
        height: 120px;

        @include mobile {
            height: auto;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
        }
    }

    input {
        background-color: $white;
        height: 40px;
        padding-left: 15px;
        border-radius: 10px;
        max-width: 330px;
        width: 100%;
    }

    &-image-wrapper {
        img {
            min-width: 50px;
            height: 50px;
        }
    }

    &-right-image-wrapper {
        img {
            width: 134px;
            height:37px;
        }
    }

    &-more-btn {
        height: 37px;
        margin-left: 10px;

        @include mobile-m {
            margin-left: 0;
        }
    }
}