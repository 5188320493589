//
// Text
//

// Text Colors
$text-color-prefix: 'tc';
$text-colors: map-merge((
  'in': inherit,
  'w': $white,
  'b': $black
),
$theme-colors);

// Text Align
$text-align-prefix: 'ta';
$text-aligns: (
  'l': left,
  'c': center,
  'r': right,
  'j': justify
);

// Text Transform
$text-transform-prefix: 'tt';
$text-transforms: (
  'l': lowercase,
  'u': uppercase,
  'c': capitalize
);

// Text Decaration
$text-decoration-prefix: 'td';
$text-decorations: (
  'n': none,
  'u': underline,
  'lt': line-through,
);

// Font Styles
$font-size-prefix: 'fs';
$font-sizes: (
  'b': $font-size-base,
  '0': 0,
  '8': 8px,
  '11': 11px,
  '10': 10px,
  '12': 12px,
  '13': 13px,
  '14': 14px,
  '15': 15px,
  '16': 16px,
  '17': 17px,
  '18': 18px,
  '20': 20px,
  '22': 22px,
  '23': 23px,
  '24': 24px,
  '26': 26px,
  '30': 30px,
);

// Font Weights
$font-weight-prefix: 'fw';
$font-weights: (
  'l': $font-weight-light,
  'n': $font-weight-normal,
  'm': $font-weight-medium,
  'sb': $font-weight-semibold,
  'b': $font-weight-bold,
  'bl': $font-weight-black
);

// Font Styles
$font-style-prefix: 'fst';
$font-styles: (
  'i': italic
);
