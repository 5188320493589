.cart {

    &-items-wrapper {
        @include mobile {
            flex-direction: column;
            align-items: center;
        }
    }

    &-form-right-col {
        .contact-form-label {
            max-width: unset !important;
            width: 100%;
        }
    }

    &-items-right {
        width: 31%;

        @include mobile {
            width: 100%;
        }
    }

    &-items-left {
        display: flex;
        flex-direction: column;
        width: 69%;

        @include mobile {
            width: 100%;
        }
    }



    &-heading {
        img {
            width: 20px;
            height: 23px;
            margin-right: 10px;
        }
    }

    .header-cart-remove-btn {
        img {
            width: 8px;
            height: 8px;
            margin-right: 8px;
        }
    }

    .header-cart-image-wrapper {
        height: 90px;
        min-width: 90px;
    }
    
    .single-product-price-quantity {
        &::before {
            content: "";
            display: block;
            width: 1px;
            height: 40px;
            background-color: $theme-color-9;
            margin-right: 20px;

            @include mobile-m {
                content: none;
            }
        }
    }

    .header-cart-items-inner {
        max-width: 1070px;
        padding-left: 20px;
        width: 100%;
        margin-left: auto;

        @include mobile {
            padding-left: 0;
        }

        .header-cart-item {
            margin-bottom: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid $theme-color-9;
        }
    }

    &-promo-heading {
        img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
    }

    &-promo-wrapper {
        max-width: 330px;

        @include mobile {
            margin: 0 auto;
            margin-bottom: 20px;
        }

        @include mobile-m {
            max-width: 100%;
        }

        input {
            width: 100%;
            border-radius: 10px;
            border: 1px solid $theme-color-9;
            padding-left: 10px;
            height: 40px;
            margin-bottom: 15px;
        }
    }

    &-btn-promo {
        width: 100%;
    }

    &-btn-pay {
        background-color: $theme-color-2;
        @extend .cart-btn-promo;
    }

    &-price {
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 20px;
        }
    }

    &-final-price {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $theme-color-15;
    }

    &-guest-form {
        width: 100%;
        align-self: flex-start;
    }

    &-price-wrapper {
        max-width: 330px;
        
        @include mobile {
            margin: 0 auto;
        }

        @include mobile-m {
            max-width: 100%;
        }

    }


    &-input-payment {
        @extend .filter-cat-label;
        input[type="radio"] {
            background-image: url("../images/polygon-color-small.png");
    
            &:checked {
                color: $white;
            }
    
            &:checked::after {
                color: $white;
            }
        }

        &:last-of-type {
            margin-bottom: 20px;
        }
    }

    &-form-cashback-level {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(230px, 1fr));
        gap: 25px;
        width: 100%;
        text-wrap: balance;
    }

    .tables-cashback-table-item {
        padding: 0;
        border-bottom: 0;
    }

    table {
        width: 100%;
        table-layout: fixed;

        th {
            @include mobile {
                width: 700px;
            }
        }

        input {
            font-size: 14px;
            font-weight: 500;
            color: $theme-color-1;
        }
    }

    .tables-address-checkbox-label {
        width: fit-content;
        span {
            white-space: nowrap;
        }
    }

    .tables-address-table-wrapper {
        .contact-form-label {
            border: 1px solid $theme-color-11;
            border-radius: 10px;

            @include mobile {
                max-width: 350px;
            }
        }
    }

    .contact-form-label {
        max-width: 450px;
        width: 100%;

        @include mobile {
            max-width: 350px;
        }

        textarea {
            border: 1px solid $theme-color-15;
            height: 90px;

            &:focus {
                border: 2px solid $theme-color-5;
            }
        }
    }
}