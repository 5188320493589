@mixin mobile {
    @media (max-width: 1230px) {
      @content;
    }
}

@mixin mobile-m {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1230px) {
    @content;
  }
}