.header-profile-dropdown {
    width: 210px;
    ul li {

        &:not(:last-of-type) {
            margin-bottom: 15px;
        }

        a {
            width: fit-content;
            display: flex;
            align-items: center;
            font-weight: 500;
            color: $theme-color-1;
            font-size: 12px;

            img {
                display: none;
                width: 6px;
                height: 10px;
                margin-right: 10px;
            }

            &.active {
                color: $theme-color-5;

                img {
                    display: block;
                }
            }

            &:hover {
                color: $theme-color-5;
            }
        }
    }
}

.header-right-login-btn + .header-cart-dropdown{
    &:before {
        right: 84px;
    }

    &:after {
        right: 84px;
    }
}