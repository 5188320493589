.main-slider {
    position: relative;
    .swiper-slide {
        width: 100%;
    }

    &-link {
        @include mobile-m {
            height: 300px;
        }

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .header-slider-btn-prev, .header-slider-btn-next {
        position: absolute;
        border-radius: 8px;
        background-color: $theme-color-5;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        top: 50%;
    }

    .header-slider-btn-prev {
        left: 10px;
    }

    .header-slider-btn-next {
        right: 10px;
    }
}