.single-product {

    &-right-col > h1 { 
        @include mobile {
            text-align: center;
        }
    }

    .products-new-banner {
        top: -1px;
        right: -1px;
    }

    &-info-wrapper {
        @include mobile {
            flex-wrap: wrap;
            row-gap: 20px;
        }
    }

    &-maker {
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 27px;
    }

    &-id {
        @extend .single-product-maker;
    }

    &-review-stars {
        gap: 5px;

        li.selected {
            i {
                color: $theme-color-5;
            }
        }

        i {
            color: $theme-color-6;
        }
    }

    &-score {
        &::after {
            content: "";
            display: inline-block;
            margin: 0 10px;
            background-color: $theme-color-6;
            width: 1px;
            height: 12px;
        }
    }

    &-comments {
        display: flex;
        align-items: center;

        &:after {
            content: "";
            display: block;
            margin-left: 10px;
            background-color: $theme-color-6;
            flex: 1 1 auto;
            height: 1px;
        }
    }

    .wp-editor {
        margin-bottom: 20px;
        overflow: hidden;
        max-height: 50px;
        transition: max-height 0.3s ease;

        p {
            line-height: 1.3;
            font-size: 15px;
            font-weight: 400;
            color: $theme-color-1;
        }

        p:not(:last-of-type) {
            margin-bottom: 10px;
        }

        p:nth-of-type(2) {
            margin-bottom: 30px;
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        &.show {
            max-height: 100%;
        }
    }

    &-quantity-down, &-quantity-up {
        min-width: 16px;
        height: 16px;
    }

    &-price-quantity {

        @include mobile {
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 10px;
        }

        input {
            padding-left: 15px;
            width: 50px;
            height: 42px;
            border-radius: 8px;
            border: 1px solid $theme-color-9;
            text-align: center;
        }
    }

    &-btn-add, &-btn-express {
        max-width: 330px;
        width: 100%;
        justify-content: flex-start !important;
        padding: 0 20px;

        span {
            font-weight: 400;
        }
    }

    &-btn-add {
        img {
            width: 16px;
            height: 16px;
        }
    }

    &-btn-express {
        img {
            width: 20px;
            height: 16px;
        }
    }

    &-check {
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(235,235,235,1) 100%);
    }

    &-check {
        &:last-of-type {
            margin-bottom: 0;
        }

        img {
            width: 32px;
            height: 28px;
        }
    }

    &-btns-wrapper-bottom {
        @include mobile {
            flex-wrap: wrap;
            row-gap: 10px;
        }
    }
}

.read-more {
    &:hover { 
        text-decoration: underline;
    }
}