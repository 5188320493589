.promo {
    &-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(274px, 1fr));
        gap: 30px;
    }

    &-link {
        height: 250px;
        overflow: hidden;
        transition: transform 0.3s ease;

        &:hover {
            transform: translateY(-10px);
        
            img {
                transform: scale(1.05);
            }
        }

        img {
            transition: transform 0.3s ease;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}