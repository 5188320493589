.compare {
    &-heading {
        img {
            width: 23px;
            height:23px;
        }
    }

    &-btn-remove {
        margin: 0 auto;
        width: fit-content;
    }

    &-image-btn-wrapper {
        @extend .tables-address-cancel;
    }

    &-price-line-through {
        text-decoration: line-through;
    }

    &-item-info-wrapper {

        &:not(:last-of-type) {
            margin-bottom: 15px;
        }
    }

    &-item-info {
        @extend .single-product-check;
        gap: 7px;
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
    }

    &-item-col-left {
        max-width: 210px;
        width: 100%;
    }

    &-item-summary {
        line-height: 1.2;
    }

    .products-image img{
        width: 168px;
    }
}