.tabs {
    &.show {
        .tabs-dropdown-outer {
            border-bottom-left-radius: 0;
        }

        .tabs-dropdown-menu {
            max-height: 350px;
        }

        .header-dropdown-btn {
            border-bottom-right-radius: 0;
        }
    }

    &-dropdown-outer {
        width: 100%;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        transform: translateZ(0);
        backface-visibility: hidden;
    }

    &-dropdown-menu {
        z-index: 10;
        left: 0;
        width: 100%;
        top: 100%;
        max-height: 0;
        overflow: hidden;
        transition: all 1s ease;
        transform: translateZ(0);
        backface-visibility: hidden;

        li {

            padding-left: 20px;

            &:first-of-type {
                padding-top: 15px;
            }

            &:last-of-type {
                padding-bottom: 20px;
            }
        }

        li.active {
            a {
                color: $theme-color-3;
            }
        }

        li a {
            display: block;
            color: $white;
            font-size: 15px;
            font-weight: 600;

            &:hover {
                color: $theme-color-3;
            }
        }

        li:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }

    .header-dropdown-btn {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        transform: translateZ(0);
        backface-visibility: hidden;
    }

    &-wrapper {
        max-width: 450px;
        width: 100%;
    }
    
    &-text {
        height: 50px;
        flex: 1;
        padding-left: 20px;
        padding-right: 15px;
    }

    .header-dropdown-btn {
        padding: 15px 20px;
        margin-left: 0;
    }

    & + .info {
        .info-item:nth-of-type(2) {
            img {
                width: 52px;
                height: 31px;
            }
        }
    }
}