//
// Floasts
//
@use 'sass:list';

@mixin  make-float($float) {
  float: $float;
}

@mixin make-floats($floats: $floats, $breakpoints: $grid-breakpoints) {
  @each $key, $value in $floats {
    .#{$flaot-prefix}-#{$key} { @include make-float($value); }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if(map-get($breakpoints, $breakpoint) > 0) {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        $media: map-get($media-prefix, up);
        @each $key, $value in $floats {
          .#{$flaot-prefix}-#{$media}#{$infix}-#{$key} { @include make-float($value); }
        }
      }

      @if(map-get($breakpoints, $breakpoint) != list.nth(list.nth($grid-breakpoints, -1), 2)) {
        @include media-breakpoint-down($breakpoint, $breakpoints) {
          @each $key, $value in $floats {
            $media: map-get($media-prefix, down);
            .#{$flaot-prefix}-#{$media}#{$infix}-#{$key} { @include make-float($value); }
          }
        }
      }
    }

    @include media-breakpoint-only($breakpoint, $breakpoints) {
      $media: map-get($media-prefix, only);
      @each $key, $value in $floats {
        .#{$flaot-prefix}-#{$media}#{$infix}-#{$key} { @include make-float($value); }
      }
    }
  }
}
