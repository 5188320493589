.tables {
    &-wrapper-inner {
        max-width: 930px;
        width: 100%;
        margin: 0 auto;

        .tables-table-wrapper{
            margin-bottom: 50px;
        }

        .tables-table-wrapper:last-of-type {
            margin-bottom: 30px;
        }

        .single-product-score { 
            &::after {
                content: none;
            }
        }

        table tr td:last-of-type {
            font-weight: 500;
            text-align: center;
        }
    }
}