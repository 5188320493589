.products-heart-checkbox {
    position: relative;
    width: 18px;
    height: 16px;
    cursor: pointer;

    .products-heart-input {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
  
        &:checked {
          ~ .products-heart-icon {
            svg.below {
              transform: scale(1.6);
              opacity: 0;
              path {
                fill: $theme-color-1;
                stroke: $theme-color-1;
              }
            }
            svg.front {
              path {
                fill: $theme-color-1;
                stroke: $theme-color-1;
              }
            }
          }
        }
    }
    
    .products-heart-icon {
        transition: transform 0.6s ease;
        z-index: 5;

        svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 16px;
        
        transition: background-color 0.6s ease, transform 0.6s ease,
        opacity 0.6s ease;

        path {
            fill: $theme-color-6;
            stroke: $theme-color-6;
        }
        }
  }
}