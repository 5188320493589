.terms {

    .wp-editor {
        p {
            font-size: 15px;
        }
        
        a {
            color: $theme-color-1;
            text-decoration: none;
        }
    }

    & + .sign-up {
        @extend .sign-up-bg;
    }
}

