.logged-heading {
    &-image-wrapper {
        height: 90px;

        img {
            min-width: 90px;
            height: 90px;
        }

        &:after {
            content: "";
            display: block;
            height: 100%;
            width: 1px;
            background-color: $theme-color-15;
            margin-left: 30px;
            margin-right: 20px;

            @include mobile {
                content: none;
            }
        }
    }

    &-btn {
        @extend .error-btn-begining;
    }

    &-btn-exit {
        &:hover {
            background-color: $theme-color-6;
        }
    }

    &-right-col {
        @include mobile {
            flex-direction: column;
        }
    }
}