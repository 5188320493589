.search-dropdown {
    top: calc(100% + 10px);
    width: 100%;
    z-index: 1000;

    &:after {
        content: "";
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index: -1;
    }

    &-items-wrapper {
        overflow: auto;
        max-height: 225px;
    }

    .header-cart-item {
        position: relative;
        border-bottom: 1px solid $theme-color-8;
        padding-bottom: 10px;
        margin-bottom: 0;

        &:hover {
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(216, 216, 216, 0.15);
                z-index: 0;
                pointer-events: none;
                border-radius: 10px;
            }
            // border-radius: 10px;
        }
    }

    .header-cart-item:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .header-cart-item:not(:first-of-type) {
        padding-top: 10px;
    }
}