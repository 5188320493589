$black: #000;
$white: #fff;
// $theme-color-5: #00919B;

$theme-color-1 : #003333;
$theme-color-2 : #004d4d;
$theme-color-3 : #e4ff00;
$theme-color-4 : #a6794d;
$theme-color-5 : #996633;
$theme-color-6 : #cacaca;
$theme-color-7 : #d50000;
$theme-color-8 : #d8d8d8;
$theme-color-9 : #dddddd;
$theme-color-10 : #1a4848;
$theme-color-11 : #dedede;
$theme-color-12: #004848;
$theme-color-13: #536da5;
$theme-color-14: #cecece;
$theme-color-15: #e5e5e5;
$theme-color-16: #f5f5f5;
$theme-color-17: #ebebeb;
$theme-color-18: #ff0000;

$text-color       : $theme-color-1;
$link-color       : $theme-color-4;
$link-hover-color : $theme-color-5;
$headings-color   : $theme-color-1;

$body-color : $text-color;
$body-bg    : $white;

$theme-colors: (
  'tc' : $text-color,
  'c1' : $theme-color-1,
  'c2' : $theme-color-2,
  'c3' : $theme-color-3,
  'c4' : $theme-color-4,
  'c5' : $theme-color-5,
  'c6' : $theme-color-6,
  'c7' : $theme-color-7,
  'c8' : $theme-color-8,
  'c9' : $theme-color-9,
  'c10' : $theme-color-10,
  'c11' : $theme-color-11,
  'c12' : $theme-color-12,
  'c13' : $theme-color-13,
  'c14' : $theme-color-14,
  'c15' : $theme-color-15,
  'c16' : $theme-color-16,
  'c17' : $theme-color-17,
  'w'  : $white,
  'b'  : $black
);
