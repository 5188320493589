.single-post-date {
    gap: 10px;
    margin-bottom: 20px;

    &-text {
        @extend .news-item-date;
        font-size: 10px !important;
        color: $white !important;
        font-weight: 500 !important;
        background-color: $theme-color-5;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 1px;
        background-color: $theme-color-9;
    }
}