// Font Weights
$font-weight-light    : 300;
$font-weight-normal   : 400;
$font-weight-medium   : 500;
$font-weight-semibold : 600;
$font-weight-bold     : 700;
$font-weight-black    : 800;

$font-family       : 'Montserrat', sans-serif;
$font-size-desktop : 16px;
$font-size-mobile  : 16px;
$font-weight       : $font-weight-normal;

// Base
$font-family-base : $font-family;
$font-size-base   : $font-size-desktop;
$font-weight-base : $font-weight;
$line-height-base : 1;

// Headings
$headings-font-family: 'Montserrat', sans-serif;
$headings-font-weight: $font-weight-normal;
$headings-line-height: 1.2;

$heading-desktop: (
  'h1': 50px,
  'h2': 60px,
  'h3': 30px,
  'h4': 20px,
  'h5': 18px,
  'h6': 16px
);

$heading-mobile: (
  'h1': 40px,
  'h2': 30px,
  'h3': 25px,
  'h4': 20px,
  'h5': 16px,
  'h6': 14px
);

$headings-link-color: inherit;
$headings-link-decoration: none;
$headings-hover-color: $theme-color-1;
$headings-link-hover-decoration: $link-hover-decoration;

$list-position: inside;

$ul-list-style-type: none;
$ol-list-style-type: decimal;
