.contact {
    .wp-editor {
        margin-bottom: 20px;

        p {
            font-size: 14px;
            margin-bottom: 0;
        }
    }

    &-form {
        max-width: 750px;
        margin: 0 auto;
    }

    &-form-inputs-wrapper {
        gap: 30px;
        margin-bottom: 30px;

        @include mobile {
            flex-direction: column;
        }

        &:last-of-type {
            margin-bottom: 40px;
        }
    }

    &-form-input-wrapper {
        flex: 1;

        @include mobile {
            width: 100%;
        }
    }

    &-form-label {
        position: relative;

        input, select, textarea {
            width: 100%;
            padding: 10.5px;
            background-color: $white;
            border-radius: 10px;
        }
        
        select {
            padding-top: 13px;
            white-space: break-spaces;
            height: 40px;
            padding-right: 40px;
            font-size: 14px;
            font-weight: 400;
        }

        textarea {
            height: 180px;
        }
    }

    .contact-form-label select {
        font-size: 15px;
    }

    &-form-select-arrow {
        display: flex;
        top: 18px;
        right: 25px;
        pointer-events: none;

        img {
            width: 12px;
            height: 6px;
        }
    }

    &-terms-notice {
        max-width: 450px;
        margin: 30px auto;

        img {
            width: 16px;
            height: 14px;
        }

        a {
            color: $white;
            text-decoration: underline;
        }

        &-text {
            line-height: 1.5;
        }
    }

    &-form-btn {
        max-width: 450px;
        width: 100%;
    }

    &-wrapper {
        background-image: url('../images/backr.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        background-origin: content-box;
    }
}

// PHARMACIST CONTACT 

.pharmacist-heading {
    gap: 15px;
    img {
        width: 24px;
        height: 24px;
    }
}