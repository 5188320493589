//
// Border
//
$border-prefix: 'b';

$border-position: (
  't': top,
  'r': right,
  'b': bottom,
  'l': left
);

// Border Width
$border-width: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);

// Border Styles
$border-styles: (
  'd': dashed,
  's': solid
);

// Border Colors
$border-colors: map-merge((
  'w': $white,
  'b': $black
),
$theme-colors);

//
// Border Radius
//
$border-radius-prefix: 'br';
$border-radius: (
  '4': 4px,
  '6': 6px,
  '8': 8px,
  '10': 10px,
  '15': 15px,
  '20': 20px,
  'c': 50%,
  'p': 50rem
);

//
// Border Image
//
$border-imgage-prefix: 'bi';

$border-image-black: linear-gradient(to right, $black 0%, transparent 35%, transparent 65%, $black 100%) 1 1;
$border-image-white: linear-gradient(to right, $white 0%, transparent 35%, transparent 65%, $white 100%) 1 1;

// $border-image: map-merge((
//   'in': inherit,
//   'w': $white,
//   'b': $black
// ),
// $theme-colors);
