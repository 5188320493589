.pagination {
    margin-top: 45px;

    a,
    span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 28px;
    color: $white;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    }

    li:not(:last-child) a,span, li.disabled{
        margin-right: 10px;
    } 

    a.page-numbers {
        background-image: url("../images/polygon-color.png");
        background-repeat: no-repeat;
    }

    a {

        &:hover {
            color: $white;
            text-decoration: none;
            background-image: url('../images/polygon-color-2.png');
        }
    }

    .current {
    color: $white;
    background-image: url('../images/polygon-color-2.png');
    }

    .screen-reader-text {
        display: none;
    }

    a.prev, a.next, span.page-numbers.dots {
        background-color: transparent;
        clip-path: none;
        border: 0;
        width: auto;
        height: auto;
        color: $theme-color-1;
    }

    a.prev, a.next {
        background-image: none;
    }

    .nav-links {
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            flex-wrap: wrap;
            row-gap: 10px;
        }
    }
}