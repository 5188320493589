@import 'config';
@import "fonts";
@import '../../node_modules/nouislider/dist/nouislider';
@import '../../node_modules/swiper/swiper-bundle';
@import './mixins/mixins';
@import '../../node_modules/flatpickr/dist/flatpickr';

@import 'main';
@import './keyframes/keyframes';
@import './keyframes/animatable';
@import './mixins/reusable';
@import './components/fields';
@import './components/Sliders';
@import './components/main-slider';
@import './components/promos';
@import './components/products-slider';
@import './components/heart-icon';
@import './components/manufacturer-slider';
@import './components/info';
@import './components/news';
@import './components/terms';
@import './components/tabs';
@import './components/contact';
@import './components/error';
@import './components/about';
@import './components/links-navigation';
@import './components/single-news';
@import './components/single-post-date';
@import './components/pagination';
@import './components/accordions';
@import './components/products';
@import './components/filter';
@import './components/search';
@import './components/map';
@import './components/brands';
@import './components/cookies';
@import './components/header-menu';
@import './components/checkbox';
@import './components/modals';
@import './components/profile-modals';
@import './components/sign-up';
@import './components/index-logged-heading';
@import './components/index-logged-info';
@import './components/profile-form';
@import './components/profile-favorites';
@import './components/cashback-table';
@import './components/promocodes-table';
@import './components/profile-address';
@import './components/category-navigation';
@import './components/single-product';
@import './components/product-review-comments';
@import './components/compare';
@import './components/compare-empty';
@import './components/dropdown-compare';
@import './components/profile-dropdown';
@import './components/search-dropdown';
@import './components/my-reviews';
@import './components/create-review-modal';
@import './components/order-success-content';
@import './components/cart';
@import './components/cart-guest';
@import './components/cart-form';
@import './components/cart-modal';
@import './components/error';

label { 
    margin-bottom: 0;
}

.header + .promo {
    margin-top: 20px;
    margin-bottom: 30px;

    @include mobile {
      margin-top: 30px;
    }
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $theme-color-2;
  }

  /* Handle */
::-webkit-scrollbar-thumb {
    background: $theme-color-3;
  }

  .measure {
    height: var(--height);
  }
  
  html.disable-scroll,
  html.disable-scroll body {
    height: calc(var(--window-inner-height) - 1px);
    overflow: hidden;
  }

  .manufacturers + .info {
    .info-wrapper {
      justify-content: center;
    }
  }

  .noUi-horizontal .noUi-handle {
    width: 25px;
    height: 10px;
    box-shadow: none;
    background: $theme-color-3;
    border: 0;
    border-radius: 4px;

    &:before {
      content: none;
    }

    &:after {
      content: none;
    }
  }

  .noUi-horizontal {
    height: 1px;
  }