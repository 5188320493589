.manufacturers {

    .swiper-slide {
        width: 100% !important;
    }

    &-wrapper {
        border: 1px solid $theme-color-6;
        border-radius: 8px;
        overflow: hidden;
    }

    &-item {
        height: 177px;
    }

    table {
        td {
            width: 235px;
            height: 175px;
            vertical-align: middle;
            text-align: center;

            @include mobile {
                height: auto;
            }
        }
    }

    table, td, th {
        border: 1px solid $theme-color-6;
    }

    table {
        border-collapse: collapse;
        border-style: hidden;
    }
}