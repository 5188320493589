.modal {
    &-cart-checkbox-label {
        cursor: pointer;
        gap: 10px;
        input {
            appearance: auto;
            background: none;
            width: auto !important;
            height: auto !important;
            &:after {
                content: none;
            }

            &:checked::after {
                content: none;
            }
        }

        img {
            width: 16px;
            height: 16px;
        }
    }

    .header-cart-image-wrapper {
        min-width: 90px;
        height: 90px;
    }

    .single-product-price-quantity {
        @extend .line-seperator-before;
    }

    &-cart-recommend-item {
        margin-bottom: 20px;
        &:last-of-type {
            margin-bottom: 40px;
        }
    }

    &-cart-add-btn {
        @extend .modal-login-btn;
        margin-bottom: 0;
    }

    .single-product-price-quantity {
        &::before {
            @include mobile-m {
                content: none;
            }
        }

        @include mobile-m {
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 10px;
        }
    }

    .cart-product-quantity-wrapper {
        @include mobile-m {
            flex-direction: column;
        }
    }
}