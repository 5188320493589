//
// Figures
//
figure {
  @extend %reset-margin;
}

img {
  vertical-align: baseline;
  border-style: none; // Remove the border on images inside links in IE 10-.
}

svg {
  // Workaround for the SVG overflow bug in IE10/11 is still required.
  // See https://github.com/twbs/bootstrap/issues/26878
  overflow: hidden;
  vertical-align: baseline;
}
