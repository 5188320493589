.links-navigation {
    flex-wrap: wrap;
    row-gap: 10px;

    &-link {
        display: flex;
        align-items: center;
        gap: 8px;

        &:not(:last-of-type) {
            margin-right: 8px;
            &:after {
                content: "";
                display: block;
                width: 2px;
                height: 12px;
                background-color: $theme-color-9;
            }
        }
    }
}