.order-success {
    &-inner {
        max-width: 930px;
        margin: 0 auto;
        padding: 0 35px;

        @include mobile {
            padding: 0;
        }
    }

    &-heading {
        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $theme-color-17;
            margin-top: 45px;
            margin-bottom: 15px;
        }
    }

    &-items {
        display: grid;
        align-items: center;
        row-gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }

    &-item-name {
        @extend .single-product-maker;
        width: fit-content;
        justify-self: flex-end;
    }

    &-info-text {
        line-height: 1.3;
    }
}