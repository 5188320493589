.swiper {
    .swiper-slide {
        width: auto;
    }
}

.swiper-pagination {
    top: 10px;
    height: fit-content;
    display: flex;
    justify-content: flex-end;

    @include mobile {
        position: static;
        margin-bottom: 20px;
    }
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: $theme-color-6;
    opacity: 1;
} 

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: $theme-color-1;
}