.header { 
    &-menu {
        width: 100%;
        top: 106px;
        left: 0;
        z-index: 10;

        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 0.6s ease;

        @include mobile {
            top: 95px;
            padding-bottom: 200px;
            overflow: auto;
        }

        &.show {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }

        @include mobile {
            height: 100svh;
        }
    }

    &-menu-left {
        position: relative;
        width: 100%;

        @include mobile {
            height: 345px;
        }
    }
    
    &-menu-left-inner {
        max-height: 335px;
        max-width: 255px;
        padding-right: 30px;
        gap: 10px;
        overflow-x: hidden;
        overflow-y: auto;

        @include mobile {
            max-width: 280px;
            max-height: 345px;
            margin: 0 auto;
        }

        .header-link-cat.active {
            a {
                background-color: $theme-color-5;
                color: $white;
            }

            .header-menu-right-inner {
                a {
                    background-color: transparent;
                }
            }

            @include desktop {
    
                .header-menu-right-inner {
                    opacity: 1;
                    pointer-events: auto;
    
                }
            }

            @include mobile {
                i::before {
                    color: $white;
                }
            }
        }

        .header-link-cat:hover {
            .header-menu-link {
                background-color: $theme-color-5;
                color: $white;
            }

           .header-menu-right-inner {
                opacity: 1;
                pointer-events: auto;

                a {
                    background-color: transparent;
                }
            } 

            i::before {
                color: $white;
            }
        }
    }

    &-menu-link {
        height: 33px;
        transition: all 0.3s ease;

        // &:not(:last-of-type) {
        //     margin-bottom: 10px;
        // }
    }

    &-menu-right-inner {
        position: absolute;
        top: 0;
        left: calc(225px + 120px);
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 45px;
        opacity: 0;
        pointer-events: none;
        margin-right: 15px;
        padding-right: 10px;
        overflow: auto;
        max-height: 100%;
        
        @include desktop {
            transition: all 0.3s ease;
        }
        
        @include mobile {
            opacity: 1;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: -125px;
            top: 0;
            width: 125px;
            height: 100%;
        }

        & li {
            margin-bottom: 15px;
        }

        @include mobile {
            grid-template-columns: repeat(1, 1fr);
            column-gap: 20px;
            z-index: 1000;
            position: unset;
            display: none;
        }
    
        a {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            color: $white;
            gap: 10px;
            height: fit-content;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }

            img {
                width: 8px;
                height: 14px;

                &:last-of-type {
                    display: none;
                }
            }

            &.active {

                img:first-of-type {
                    display: none;
                }

                img:last-of-type {
                    display: block;
                }
            }

            &:hover {
                img:first-of-type {
                    display: none;
                }

                img:last-of-type {
                    display: block;
                }
            }
        }
    }

    &-menu-image {
        max-width: 310px;
        width: 100%;
        height: 335px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-menu-right {

        @include mobile {
        
            &.show {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    // MOBILE MENUS

    &-mobile-menu-left-inner {
        padding-top: 10px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        transition: opacity 0.3s ease;
        opacity: 1;
        pointer-events: auto;

        &.hide {
            opacity: 0;
            pointer-events: none;
        }
    }

    &-mobile-menu-right-inner {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: 280px;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        .header-menu-right-inner { 
            position: static;
            gap: 15px;
            max-height: 230px;
            overflow: auto;
            opacity: 1;
        }

        &.show {
            opacity: 1;
            pointer-events: auto;

            .header-menu-right-inner { 
                pointer-events: auto;
            }
        }
    }

    &-mobile-cat-back-btn {
        @extend .single-product-maker;
        width: 180px;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    &-mobile-food-link {
        max-width: 280px;
        width: 100%;
    }

    &-link-cat {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include mobile {
            position: relative;
        }
    }

    &-mobile-go-to-cat {
        top: 8px;
        position: absolute;
        right: 10px;
        z-index: 2;
    }
}