.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2000;
    transition: opacity 0.6s ease;
    opacity: 0;
    
    &.show {
        background-color: rgba(0,0,0,0.8);
        opacity: 1;
        pointer-events: all;
    }

    &-heading-image {
        background-image: url("../images/polygon.png");
        min-width: 52px;
        height: 46px;
        background-size: cover;
    }

    &-wrapper-inner {
        max-width: 470px;
        width: 100%;
    }

    &-wrapper-outer {
        max-width: 690px;
        margin: 0 auto;
        max-height: 100%;
        display: flex;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;

        @extend .contact-wrapper;
        background-image: url("../images/modal-backr.png");
    }

    &-heading-wrapper {
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }

    &-close {
        top: 15px;
        right: 25px;

        @media (max-width: 767.98px) {
            right: 20px;
        } 

        img {
            width: 16px;
            height: 16px;
        }
    }



    &-inner {
        max-width: 470px;
        padding: 0 10px;
        width: 100%;
        margin: 0 auto;
        max-height: 100%;
        overflow: auto;
        padding-bottom: 1px;
    }

    &-login-fb {
        height: 40px;
    }

    &-form-label {
        margin-bottom: 15px;
        input {
            border: 1px solid $theme-color-14;
            border-radius: 10px;
            padding-left: 15px;
            height: 40px;
            width: 100%;

            &:focus {
                border: 2px solid $theme-color-5;
            }
        }

        &:last-of-type {
            margin-bottom: 20px;
        }

        img {
            pointer-events: none;
            display: flex;
            width: 14px;
            height: 10px;
        }

        textarea {
            width: 100%;
            height: 150px;
            border-radius: 10px;
            padding-left: 10px;
            padding-top: 5px;
            border: 1px solid $theme-color-14;

            &:focus {
                border: 2px solid $theme-color-5;
            }
        }

        &.show {
            .modal-show-password { 
                &:after {
                    content: none;
                }
            }
        }
    }

    &-show-password {
        position: absolute;
        top: 14px;
        right: 14px;

        @include mobile {
            top: 10px;
            width: 20px;
            height: 20px;
            align-items: center;
            justify-content: center;
        }

        &:after {
            content: "";
            display: block;
            background-image: url("../images/slash.png");
            position: absolute;
            top: -3px;
            left: -2px;
            width: 17px;
            height: 17px;

            @include mobile {
                top: 1px;
                left: 2px;
            }
        }
    }

    .contact-terms-notice {
        padding: 0 30px;
        margin-bottom: 40px;
        margin-top: 0;
    }

    .contact-terms-notice-text {
        color: $black;

        a {
            color: $black;
            text-decoration: underline;
        }
    }

    &-login-btn {
        @extend .contact-form-btn;
        max-width: 370px;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    .modal-trigger {
        &:hover {
            text-decoration: underline;
        }
    }

    &-deactivate-btn {
        margin: 0 auto;
        max-width: 370px;
        width: 100%;
        padding: 0;
    }

    &-upload-review-btn {
        @extend .contact-form-btn;
        max-width: 370px;
        margin: 0;
    }
}

.modal[data-attribute="modal-login"] {
    .modal-heading-image {
        img {
            width: 28px;
            height: 32px;
        }
    }
}

.modal[data-attribute="modal-register"] {
    .modal-heading-image {
        img {
            width: 28px;
            height: 28px;
        }
    }
}

.modal[data-attribute="modal-forgotten-password"] {
    .modal-heading-image {
        img {
            width: 28px;
            height: 25px;
        }
    }
}

.modal[data-attribute="modal-cart"] {
    .modal-wrapper-inner {
        max-width: 100%;
    }

    .modal-inner {
        padding: 0;
        max-width: 100%;
    }

    .modal-heading-image {
        img {
            width: 26px;
            height: 29px;
        }
    }
}