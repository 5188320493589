//
// Displays
//
@use 'sass:list';

@mixin make-display($value) {
  display: $value;
}

@mixin make-displays($displays: $displays, $breakpoints: $grid-breakpoints) {
  @each $key, $value in $displays {
    .#{$display-prefix}-#{$key} { @include make-display($value); }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if(map-get($breakpoints, $breakpoint) > 0) {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        $media: map-get($media-prefix, up);
        @each $key, $value in $displays {
          .#{$display-prefix}-#{$media}#{$infix}-#{$key} { @include make-display($value); }
        }
      }

      @if(map-get($breakpoints, $breakpoint) != list.nth(list.nth($grid-breakpoints, -1), 2)) {
        @include media-breakpoint-down($breakpoint, $breakpoints) {
          $media: map-get($media-prefix, down);
          @each $key, $value in $displays {
            .#{$display-prefix}-#{$media}#{$infix}-#{$key} { @include make-display($value); }
          }
        }
      }
    }

    @include media-breakpoint-only($breakpoint, $breakpoints) {
      $media: map-get($media-prefix, only);
      @each $key, $value in $displays {
        .#{$display-prefix}-#{$media}#{$infix}-#{$key} { @include make-display($value); }
      }
    }
  }
}
