.animatable {
    &.show {
      opacity: 0;
      animation-name: show;
      animation-duration: 1.8s;
      animation-play-state: running;
      animation-iteration-count: 1;
      animation-fill-mode: forwards; 
    }

    &.close {
      opacity: 1;
      animation-name: close;
      animation-duration: 0.5s;
      animation-play-state: running;
      animation-iteration-count: 1;
      animation-fill-mode: forwards; 
    }
}