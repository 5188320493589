//
// Global Variables
//

$gutter: 20px;

$escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29'),
);

$image-hover: scale(1.1);
$transition: 300ms ease-in-out;
