.brands {

    &-col-left {
        padding-top: 17px;
        padding-bottom: 17px;

        @media (min-width: 992px) and (max-width: 1348px) {
            padding-top: 9px;
            padding-bottom: 9px;
        }
    }

    &-col-left, &-col-right {
        height: 100%;
        display: flex;
        align-items: center;
    }

    &-col-right {
        gap: 5px;
        flex-wrap: wrap;
        padding-top: 17px;
        padding-bottom: 17px;
    }

    &-letter {
        color: $white;
        font-size: 16px;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }

    &-pointer-letter {
        width: 38px;
        height: 38px;

    }

    .manufacturers-wrapper {
        width: fit-content;

        table td {
            width: 235px;
            height: 175px;
            vertical-align: middle;
            text-align: center;

            @include mobile {
                height: auto;
            }
        }

        table, td, th {
            border: 1px solid $theme-color-6;
        }

        table {
            border-collapse: collapse;
            border-style: hidden;
        }
    }
    
    .manufacturers-names {
        display: flex;
        align-items: center;
        margin-top: 10px;

        p {
            max-width: 235px;
            width: 100%;
            font-size: 12px;
            font-weight: 500;
            color: $theme-color-1;
            text-align: center;
        }
    }

    & + .sign-up {
        @extend .sign-up-bg;
    }
}