.header {

  &-mobile-search-btn {

    @include mobile {
      margin-left: 10px;
    }

    &.active {
      img:first-of-type {
        display: none;
      }

      img:nth-of-type(2) {
        display: block;
      }
    }

    img {
      width: 18px;
    }
    img:first-of-type {
      height: 18px;
    }

    img:nth-of-type(2) {
      height: 17px;
      display: none;

    }
  }

  &-middle {
    width: 100%;

    @include mobile {
      height: 42px;
    }
  }

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    animation: Sticky 0.6s ease;
  }

  &-logo-btn {
    transition: all 0.3s ease;
    pointer-events: auto;

    img {
      min-width: 270px;
      height: 53px;
    }

    @include mobile {
      &.hide {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &-dropdown-btn {
    padding: 10px 11px;

    @include mobile {
      margin-left: 15px;
      padding: 0;
    }
  }

  &-hamburger {
    position: relative;
    width: 20px;
    height: 20px;

    .line-1, .line-2, .line-3 {
      position: absolute;
      left: 0;
      top: 46%;
      width: 100%;
      height: 2px;
      background-color: $white;
      transition: all 0.4s cubic-bezier(0.84, 0.06, 0.52, 1.8);
    }

    .line-1 {
      transform: translateY(-5px);
      animation-delay: 0.1s;
    }
    .line-3 {
      transform: translateY(5px);
      animation-delay: 0.25s;
    }
    .line-1.first-line {
      transform: rotate(40deg);
    }
    .line-3.third-line {
      transform: rotate(-40deg);
    }
    .line-2.second-line {
      opacity: 0;
    }
  }

  &-search-btn {
    cursor: pointer;
    width: 34px;
    height: 34px;
    border-radius: 10px;
    border-top-left-radius: 0;

    img {
      width: 18px;
      height: 18px;
    }
  }

  &-search-form-wrapper {
    transition: all 0.3s ease;
    transition-delay: 0s;
    @include mobile {
      opacity: 0;
      pointer-events: none;
      &.show {
        opacity: 1;
        pointer-events: auto;
        transition-delay: 0.3s;
      }
    }
  }

  &-search-form {
    position: relative;
    z-index: 1005;
    input {
      padding-right: 10px;
      width: 100%;
    }
  }

  &-search-wrapper { 
    gap: 20px;
  }

  &-right {
    align-self: flex-start;
  }

  &-right-wrapper {
    max-width: 385px;
    width: 100%;
    height: 68px;
    margin-left: auto;
    background-color: $theme-color-1;
    border-bottom-left-radius: 25px;
    gap: 20px;

    @include mobile {
      max-width: 100%;
      gap: 10px;
      border-bottom-left-radius: 0;
    }
  }

  &-image-wrapper {
    background-image: url("../images/polygon.png");
    background-repeat: no-repeat;
    position: relative;
    width: 32px;
    height: 28px;
  }

  &-right-login-btn, &-right-compare-btn {
    img {
      width: 14px;
    }
  }

  &-right-login-btn, &-right-cart-btn {
    img {
      height: 16px;
    }
  }

  &-right-login-btn {
    & + .header-cart-dropdown {
      @include mobile {
        right: -77px;
      }

      @include mobile-m {
        right: -180px;

        &::before {
          left: 10px;
        }

        &::after {
          left: 10px;
        }
      }
    }
  }

  &-right-compare-btn {
    img {
      height: 14px;
    }

    & + .header-cart-dropdown {
      @include mobile {
        right: -60px;
      }

      @include mobile-m {
        right: -203px;
      }
    }
  }

  &-right-cart-btn {
    img {
      width: 16px;
    }

    & + .header-cart-dropdown {
      @include mobile-m {
        right: -103px;

        &:before {
          left: 102px;
        }

        &:after {
          left: 102px;
        }
      }
    }
  }

  &-right-btn {
    gap: 5px;

    &:hover{
        text-decoration: underline;
    }
  }

  &-btn-logged {
    &:after {
      content: "";
      display: block;
      background-image: url('../images/logged-arrow.png');
      width: 7px;
      height: 4px;

      @include mobile {
        content: none;
      }
    }
  }

  &-promo {
    padding-right: 30px;
    img {
      width: 20px;
      height: 20px;
    }
  }

  &-slider-wrapper {
    position: relative;
    flex: 1;
    gap: 30px;
    overflow: hidden;
  }

  &-slider-btn-next, &-slider-btn-prev {
    display: flex;
    cursor: pointer;
    z-index: 5;
    img {
      min-width: 10px;
      height: 16px;
    }
  }

  &-bot-right {
    gap: 20px;

    &:before {
      content: "";
      display: block;
      height: 16px;
      width: 1px;
      margin-right: 10px;
      background-color: $theme-color-2;
    }
  }

  &-bot .row{
    background: rgb(0,51,51);
    background: linear-gradient(90deg, rgba(0,51,51,1) 0%, rgba(0,79,79,1) 100%);
  }

  &-swiper-promo-link {
    flex: 1;
    &:hover {
      text-decoration: underline;
    }
  }

  &-cart-dropdown {
    top: calc(100% + 10px);
    right: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.6s ease;

    &.show {
      opacity: 1;
      pointer-events: auto;


    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -14px;
      right: 61px;
      width: 0;
      height: 0;
      border-bottom: 7px solid $theme-color-9;
      border-top: 7px solid transparent;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;

      @include mobile-m {
        right: unset;
        left: 56px;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -14px;
      right: 61px;
      width: 0;
      height: 0;
      border-bottom: 7px solid $white;
      border-top: 7px solid transparent;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      z-index: 1;

      @include mobile-m {
        right: unset;
        left: 56px;
      }
    }
  }

  &-cart-empty-text {
    display: block;
    width: 190px;
  }

  &-cart-items-outer {
    width: 348px;

    @include mobile-m {
      width: 280px;
    }
  }

  &-cart-remove-btn {
    min-width: 8px;
    img {
      width: 8px;
      height: 8px;
    }
  }

  &-cart-image-wrapper {
    height: 60px;
    max-width: 60px;
  }

  &-cart-item {
    &:not(:last-of-type) {
      border-bottom: 1px solid $theme-color-9;
      padding-bottom: 15px;
    }

    &:not(:first-of-type) {
      padding-top: 15px;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  &-cart-btn-link {
    max-width: 307px;
    width: 100%;
  }

  // PROMO MOBILE

  &-promo-mobile {
    width: 100%;

    .header-promo-dropdown-btn {
      max-width: 100%;
    }
  }

  &-promo-dropdown {
    top: 163px;
    z-index: 100;
    width: 100%;
    height: 100svh;
    background-color: $theme-color-2;
    opacity: 0;
    transition: all 0.3s ease;
    pointer-events: none;

    &.show {
      opacity: 1;
      pointer-events: auto;
    }

    .header-promo-dropdown-btn {
      max-width: 180px;
    }
  }

  &-promo-dropdown-btn {
    max-width: 180px;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-right: 0;
    @extend .header-promo;
    padding-right: 0;

    img {
      width: 20px;
      height: 20px;

      &:last-of-type {
        width: 16px;
        height: 10px;
        margin-left: 10px;
      }
    }
  }

  &-promo-dropdown-inner {
    max-height: 335px;
    overflow: auto;

    .header-swiper-slide-items {
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }

    @include mobile {
      .swiper-slide { 
        height: auto;
        margin-bottom: 20px;
      }
    }
  }

  &-cart-items-count {
    top: -9px;
    right: -10px;
    width: 20px;
    height: 20px;
  }

  &-bot-right-links {
    img {
      height: 16px;
    }
  }

  &-bot-right-links:first-of-type {
    img {
      width: 12px;
    }
  }

  &-bot-right-links:nth-of-type(2), &-bot-right-links:last-of-type {
    img {
      width: 16px;
    }
  }
}
